<template>
  <div>
    <CampusHeader
        :activeTab="0"
        :can-all="true"
        :can-create="canCreate"
        :can-select="true"
        :create-text="createText"
        :header-text="headerText"
        :header_tabs="[{ title: `${tabEntity} List` }]"
        :manage-campuses="true"
        @start-creating="startCreateIncident"
    ></CampusHeader>
    <section class="section pt-4 box">
      <div class="">
        <div class="columns is-multiline">
          <div class="column is-12">
            <b-field group-multiline grouped>
              <b-field expanded>
                <b-select v-model="statusFilter" expanded
                          placeholder="Status">
                  <option :value="null">All</option>
                  <option v-for="status in $store.state.incidents.statuses" :key="status.id" :value="status.id">
                    {{ status.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field expanded>
                <b-select v-model="categoryFilter" expanded
                          placeholder="Category">
                  <option :value="null">All</option>
                  <optgroup v-for="categoryGroup in $store.state.incidents.categories" :key="categoryGroup.id"
                            :label="categoryGroup.name">
                    <option v-for="category in categoryGroup.items" :key="category.id" :value="category.id">
                      {{ category.name }}
                    </option>
                  </optgroup>
                </b-select>
              </b-field>
              <b-field expanded>
                <b-select v-model="chargeFilter" expanded
                          placeholder="Charge">
                  <option :value="null">All</option>
                  <optgroup v-for="chargeGroup in $store.state.incidents.charges" :key="chargeGroup.id"
                            :label="chargeGroup.name">
                    <option v-for="charge in chargeGroup.items" :key="charge.id" :value="charge.id">
                      {{ charge.name }}
                    </option>
                  </optgroup>
                </b-select>
              </b-field>
              <b-field expanded>
                <b-select v-model="actionFilter" expanded
                          placeholder="Disciplinary Action">
                  <option :value="null">All</option>
                  <option v-for="action in $store.state.incidents.actions" :key="action.id"
                          :value="action.id">
                    {{ action.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field>
                <b-datepicker
                    editable
                    :years-range="[-100,100]"
                    v-model="$store.state.dates.dateMin"
                    placeholder="Start date"
                    :icon="$tc('icons.calendar')"
                ></b-datepicker>
              </b-field>
              <b-field>
                <b-datepicker
                    editable
                    :years-range="[-100,100]"
                    v-model="$store.state.dates.dateMax"
                    placeholder="End date"
                    :icon="$tc('icons.calendar')"
                ></b-datepicker>
              </b-field>
              <b-field expanded>
                <UsersFilter
                    :campus_id="$store.state.campus.all_campuses?null:  parseInt($store.state.campus.selected_campus_id)"
                    :placeholder="'Creator'"
                    :selected_user_id="creator_id"
                    :type="'autocomplete'"
                    :with_permission="'create incidents'"
                    @cleared="creator_id=null"
                    @selected="option => creator_id = option.id"></UsersFilter>
              </b-field>
              <b-field>
                <b-button type="is-primary" @click="clear()">
                  Clear
                </b-button>
              </b-field>
            </b-field>

          </div>
        </div>
        <hr>
        <b-table
            :bordered="false"
            :data="incidents"
            :hoverable="true"
            :loading="loadingData"
            :scrollable="true"
            :striped="true"
            @sort="onSort"
        >
          <b-table-column
              v-slot="props"
              field="id"
              label="ID"
              numeric
              sortable
              width="40"
          >{{ props.row.id }}
          </b-table-column>


          <b-table-column
              v-slot="props"
              field="creator_id"
              label="Creator"
              sortable
          >
            {{ props.row.creator.first_name }} {{ props.row.creator.last_name }}
          </b-table-column>

          <b-table-column
              v-slot="props"
              field="status_id"
              label="Status"
              sortable
          >
            {{ $store.state.incidents.statuses.find(status => status.id === props.row.status_id).name }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              field="category_id"
              label="Category"
              sortable
          >
            {{
              $store.state.incidents.ungroupedCategories.find(category => category.id === props.row.category_id).name
            }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              field="charge_id"
              label="Charge"
              sortable
          >
            {{ $store.state.incidents.ungroupedCharges.find(charge => charge.id === props.row.charge_id).name }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              field="disciplinary_action_id"
              label="Disciplinary Action"
              sortable
          >
            {{
              props.row.disciplinary_action_id ? $store.state.incidents.actions.find(action => action.id === props.row.disciplinary_action_id).name : 'None recorded'
            }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              field="incident_date"
              label="Incident Date"
              sortable
          >
            {{ props.row.incident_date }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              field="created_at"
              label="Created At"
              sortable
          >
            {{ props.row.created_at }}
          </b-table-column>

          <b-table-column
              v-slot="props"
              centered
              label="Actions"

          >
            <b-dropdown append-to-body aria-role="list">
              <template #trigger="{ active }">
                <b-button
                    :icon-right="active ? 'menu-up' : 'menu-down'"
                    label="Actions"
                    type="is-primary"/>
              </template>


              <b-dropdown-item aria-role="listitem" @click="viewIncident(props.row)">View{{
                  canEdit ? '/Edit' : ''
                }}
              </b-dropdown-item>

              <b-dropdown-item v-if="canDelete" aria-role="listitem"
                               @click="startDelete(props.row)">Delete
              </b-dropdown-item>
            </b-dropdown>
          </b-table-column>
        </b-table>
        <b-pagination
            :current="page"
            :per-page="limit"
            :range-after="2"
            :range-before="2"
            :total="meta.total"
            aria-current-label="Current page"
            aria-next-label="Next page"
            aria-page-label="Page"
            aria-previous-label="Previous page"
            class="mt-4"
            v-on:change="setPage"
        ></b-pagination>
      </div>
    </section>
  </div>
</template>

<script>
import CampusHeader from "@/components/panelled-dash/CampusHeader";
import {mapState} from "vuex";
import Incident from "@/models/Incident";
import UsersFilter from "@/components/panelled-dash/UsersFilter";
import IncidentForm from "@/components/incidents/IncidentForm";

export default {
  name: "Incidents.index",
  components: {CampusHeader, UsersFilter},
  data() {
    return {

      search: null,
      loadingFilter: false,
      incidentsDataHold: null,
      meta: Object,
      page: 1,
      use_modal: false,
      limit: 20,
      loadingData: false,
      statusFilter: null,
      creator_id: null,
      categoryFilter: null,
      chargeFilter: null,
      actionFilter: null,
      order_by: 'created_at',
      order_direction: 'asc',
    };
  },
  props: {

    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    }, canEdit: {
      type: Boolean,
      default() {
        return false;
      },
    }, canManage: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default() {
        return "Incidents";
      },
    },
    createText: {
      type: String,
      default() {
        return "Log Incident";
      },
    },
    tabEntity: {
      type: String,
      default() {
        return "Incident";
      },
    },

  },
  methods: {


    clear() {
      this.statusFilter = null
      this.creator_id = null
      this.categoryFilter = null
      this.chargeFilter = null
      this.actionFilter = null
      this.$store.state.dates.dateMin = null
      this.$store.state.dates.dateMax = null
    },
    onSort(field, order) {

      this.order_by = field;
      this.order_direction = order;
    },

    startDelete(incident) {
      this.$buefy.dialog.confirm({
        title: `Deleting Incident`,
        confirmText: `Delete Incident`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this incident?`,
        onConfirm: () =>
            Incident.Delete(incident.id)
                .then(() => {
                  this.$buefy.snackbar.open(`Incident deleted!`);
                  this.setPage(this.page)
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
    startCreateIncident() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          canManage: this.canManage,
          canEdit: this.canEdit,
          canCreate: this.canCreate,
          canDelete: this.canDelete,
        },
        component: IncidentForm,
        fullScreen: false,
        trapFocus: true,
      })
    },
    viewIncident(Incident) {
      this.setQuery({incident_id: Incident.id})
      this.$buefy.modal.open({
        parent: this,
        props: {
          edit: true,
          inModal: true,
          canManage: this.canManage,
          canEdit: this.canEdit,
          canCreate: this.canCreate,
          canDelete: this.canDelete,
          incident: Incident
        },
        component: IncidentForm,
        fullScreen: false,
        trapFocus: true,
        onCancel: () => {

          this.removeQuery(['incident_id'])
        },

      })
    },

    setPage(pageNumber, initial = false) {
      this.loadingData = true;
      this.incidentsDataHold = this.incidents;
      Incident.deleteAll();
      this.page = pageNumber;
      Incident.FetchAll(
          {
            page: this.page,
            limit: this.limit,
          },
          this.filters, ['creator', 'users']
      ).then(
          ({
             response: {
               data: {meta},
             },
           }) => {
            this.meta = meta;
            this.loadingData = false;
            this.incidentsDataHold = null;
            if (!initial) {
              this.$cookies.set(
                  `incident_filters`,
                  {
                    page: this.page,
                    statusFilter: this.statusFilter,
                    creator_id: this.creator_id,
                    categoryFilter: this.categoryFilter,
                    chargeFilter: this.chargeFilter,
                    actionFilter: this.actionFilter,
                    order_by: this.order_by,
                    order_direction: this.order_direction,
                    dateMax: this.$store.state.dates.dateMax,
                    dateMin: this.$store.state.dates.dateMin
                  },
                  "1d"
              );
            }
          }
      );
    },
  },
  watch: {
    filters() {
      this.setPage(1);
    },
  },
  computed: {
    ...mapState('campus', ["selected_campus_id", "all_campuses"]),
    filters() {
      return {
        ...(this.order_direction
            ? {
              order_direction: this.order_direction,
            }
            : {}),
        ...(this.order_by
            ? {
              order_by: this.order_by,
            }
            : {}),

        ...(this.statusFilter
            ? {
              status: this.statusFilter,
            }
            : {}),
        ...(this.creator_id
            ? {
              creator: this.creator_id,
            }
            : {}),
        ...(this.categoryFilter
            ? {
              category: this.categoryFilter,
            }
            : {}),
        ...(this.chargeFilter
            ? {
              charge: this.chargeFilter,
            }
            : {}),
        ...(this.actionFilter
            ? {
              disciplinaryAction: this.actionFilter,
            }
            : {}),
        ...(this.$store.state.dates.dateMin
            ? {
              start_date: this.$store.getters['dates/formattedDateMin'],
            }
            : {}),
        ...(this.$store.state.dates.dateMax
            ? {
              end_date: this.$store.getters['dates/formattedDateMax'],
            }
            : {}),
        ...(this.$store.state.dates.dateMax!==null || this.$store.state.dates.dateMin!==null
            ? {
              filter_date: true
            }
            : {}),
        ...(this.$store.state.campus.selected_campus_id && !this.$store.state.campus.all_campuses
            ? {
              campus: this.$store.state.campus.selected_campus_id,
            }
            : {}),

      };
    },
    incidents() {
      if (this.incidentsDataHold === null) {
        return Incident.query().with('creator').get();
      } else {
        return this.incidentsDataHold;
      }
    },

  },
  mounted() {
    this.$store.dispatch("loader/show");
    if (this.$cookies.isKey(`incident_filters`)) {
      let filters = this.$cookies.get(`incident_filters`);
      this.statusFilter = filters.statusFilter
      this.creator_id = filters.creator_id
      this.categoryFilter = filters.categoryFilter
      this.chargeFilter = filters.chargeFilter
      this.actionFilter = filters.actionFilter
      this.order_by = filters.order_by
      this.order_direction = filters.order_direction
      if (filters.dateMax !== null) {
        this.$store.state.dates.dateMax = new Date(filters.dateMax)
      }
      if (filters.dateMin !== null) {
        this.$store.state.dates.dateMin = new Date(filters.dateMin)
      }

      this.$cookies.remove(`incident_filters`);
    }
    if (this.$route.query.incident_id) {
      Incident.FetchById(this.$route.query.incident_id, ['users']).then(() => {
        this.viewIncident(Incident.query().where('id', parseInt(this.$route.query.incident_id)).first())
      }).catch(err => {
        this.handleError(err)
      })
    }
    this.$store.dispatch("loader/hide");
    this.setPage(this.page, true);

  },
};
</script>
