<template>
  <IncidentIndex
      :can-edit="!!$store.getters['entities/user-permissions/find']('edit incidents')"
      :can-delete="!!$store.getters['entities/user-permissions/find']('delete incidents')"
      :can-create="!!$store.getters['entities/user-permissions/find']('create incidents')"
      :can-manage="!!$store.getters['entities/user-permissions/find']('administrate incidents')"
  ></IncidentIndex>
</template>
<script>
import IncidentIndex from "@/components/incidents/IncidentIndex";

export default {
  name: "Incidents.index",
  components: { IncidentIndex },
};
</script>